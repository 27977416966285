<template>
    <div>
        <v-btn
            :loading="loading.importPermitAdvice"
            small
            class="ml-2 lighten-2"
            v-if="updateMode && !listMode"
            @click.stop="printImportPermitAdvice()"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <v-list-item class="font-sm" @click.stop="printImportPermitAdvice()" v-if="updateMode && listMode">
            <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.importPermitAdvice"/>
            <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
            <span>{{ $t('message.pdf') }}</span>
        </v-list-item>
        <PrintIpaNewBlob
            :ipa-id="importPermitAdviceId"
            :key="updateImportPermitAdviceKey"
            @pdf-uploading="importPermitAdviceUploading"
            @pdf-uploaded="importPermitAdviceUploaded"
            @pdf-not-uploaded="importPermitAdviceNotUploaded"
            v-if="hasImportPermitAdvice"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";

const PrintIpaNewBlob = () => import("Views/v1/ipas/PrintIpaNewBlob");
export default {
    name: "PrintImportPermitAdviceButton",
    props: ['importPermitAdviceId','updateMode','listMode'],
    components: {PrintIpaNewBlob},
    data() {
        return {
            currentImportPermitAdvicePdf: null,
            hasImportPermitAdvice: false,
            loading: {
                importPermitAdvice: false,
            },
            updateImportPermitAdviceKey: 1
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/ipas/" + this.importPermitAdviceId, {
                        params: {
                            fields: [
                                'ipas.location AS ipas__location'
                            ]
                        }
                    })
                    .then(response => {
                        const importPermitAdvice = response.data.data[0]
                        if(importPermitAdvice){
                            this.currentImportPermitAdvicePdf = importPermitAdvice.Ipa.location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        importPermitAdviceNotUploaded() {
            this.loading.importPermitAdvice = false
            this.hasImportPermitAdvice = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        importPermitAdviceUploaded(url) {
            this.currentImportPermitAdvicePdf = url
            this.loading.importPermitAdvice = false
            this.viewFile(this.currentImportPermitAdvicePdf)
            this.hasImportPermitAdvice = false
        },
        importPermitAdviceUploading() {
            this.loading.importPermitAdvice = true
        },
        printImportPermitAdvice() {
            this.loading.importPermitAdvice = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentImportPermitAdvicePdf != null){
                        this.viewFile(this.currentImportPermitAdvicePdf)
                            .then(() => this.loading.importPermitAdvice = false)
                            .catch(() => this.loading.importPermitAdvice = false)
                    } else {
                        this.hasImportPermitAdvice = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.importPermitAdvice = false
                })
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        }
    }
}
</script>

<style scoped>

</style>